.detailedcard__cards-phaseContent {
  position: relative;
  border: 1px solid #d3d3d3;
  padding-left: 15px;
  max-width: 710px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
}

.detailedcard__cards-desc {
  padding: 15px;
  padding-left: 0;
  font-size: 15px;
}
.detailedcard__cards-time {
  position: relative;
  border: 1px solid #d3d3d3;
  padding-right: 60px;
  padding-left: 20px;
  max-width: 345px;
  width: 100%;
  border-radius: 5px;
}
.detailedcard__cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  margin-left: 25px;
}
.detailedcard__navbar-title {
  padding-left: 25px;
  font-size: 14px;
}
.detailedcard__cards-title {
  position: relative;
  border: 1px solid #d3d3d3;
  padding-left: 15px;
  max-width: 710px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 20px;
}
.detailedcard__cards-overview {
  position: relative;
  border: 1px solid #d3d3d3;
  padding-left: 15px;
  max-width: 710px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 20px;
  height: 10vh;
}
.detailedcard__cards-package_content {
  position: relative;
  border: 1px solid #d3d3d3;
  padding-left: 15px;
  max-width: 223px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 20px;
  padding: 20px;
}
.detailedcard__cards-package {
  display: flex;
  gap: 20px;
}
.detailedcard__buttons-container {
  display: flex;
  flex-direction: column;
}
.detailedcard__buttons-content {
  background: #f5f6f8;
  border-radius: 10px;
  margin-top: 10px;
  max-width: 48px;
  width: 100%;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.detailedcard__buttons-btn {
  width: 48px;
  height: 48px;
  padding: 15px;
  padding-top: 15px;
  border-radius: 999px;
  transition: 200ms;
  font-size: 14px;
}
.detailedcard__comments-item {
  position: relative;
  border: 1px solid #d3d3d3;
  padding-left: 15px;
  max-width: 650px;
  width: 100%;
  border-radius: 5px 15px 15px 5px;
  font-size: 14px;
  margin-top: 10px;
}
.detailedcard__comments-fio {
  padding-top: 10px;
  color: #999;
  font-size: 12px;
}
.detailedcard__comments-date {
  padding-bottom: 10px;
  color: #999;
  font-size: 12px;
}

.detailedcard__comments {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
}
.detailedcard__cards-overview_overflow {
  overflow: auto;
  height: 8vh;
}
.detailedcard__navbar {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  text-align: center;
}
.detailedcard__navbar-avatarGroup {
  display: flex;
  align-items: center;
  gap: 8px;
}
.detailedcard__navbar-executors {
  margin-top: 5px;
}

@media (max-width: 600px) {
  .detailedcard__cards,
  .detailedcard__cards-title,
  .detailedcard__cards-overview,
  .detailedcard__cards-phaseContent,
  .detailedcard__cards-package_content {
    margin-left: -1px;
    max-width: 100%;
  }
  .detailedcard__cards-time {
    flex-direction: column;
    max-width: none;
  }
  .detailedcard__cards-package {
    flex-direction: column;
    gap: 10px;
  }

  .detailedcard__buttons-container {
    align-items: center;
    margin-top: 20px;
    position: absolute;
    left: 5;
    top: -10px;
  }

  .detailedcard__buttons-content {
    flex-direction: row;
    justify-content: center;
    max-width: 100px;
    width: 100%;
  }

  .detailedcard__buttons-btn {
    padding: 10px;
    margin: 5px;
  }

  .detailedcard__navbar {
    flex-direction: column;
    align-items: center;
  }

  .detailedcard__navbar-avatarGroup {
    flex-wrap: wrap;
    align-items: center;
  }

  .detailedcard__cards-desc {
    font-size: 14px;
  }
  .detailedcard__navbar-title {
    margin-right: 25px;
  }
  .detailedcard__comments {
    margin-top: auto;
    margin-top: 20px;
    padding-bottom: 10px;
  }
  .detailedcard__comments-desc {
    max-width: 200px;
    width: 100px;
  }
  .detailedcard__comments-item {
    border-radius: 5px;
    max-width: 100%;
    height: auto;
    overflow-x: hidden;
  }
  .detailedcard__buttons-content {
    position: sticky;
  }
}
